import React, { Component } from 'react';
import Content from '../../components/Content';
import './style.css';

export default class HomePage extends Component {
  render(){
    return (
      <Content/>
    )
  }
}