import React, { Component } from 'react';
import './style.css';

export default class Footer extends Component {
  render(){
    return (
      <div className="footer-container">
        <div className="footer-content"></div>
      </div>
    )
  }
}